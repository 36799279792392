/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */

import { FORMAT } from "@/constants/settings";

export const ACTIONS_REWRITE = {
  SUMMARIZATION: "rewrite-summarization",
  SIMPLIFICATION: "rewrite-simplification",
  CLARIFICATION: "rewrite-clarification",
  CONSISTENCY: "rewrite-consistency",
};

export const ACTIONS_FILES = {
  WAITING: "waiting",
  READY: "ready",
  IN_PROGRESS: "in-progress",
  FAIL: "fail",
  SUCCESS: "success",
};

export const ACTIONS_DESIGNER = {
  SKETCH_BOOK_COVER: "sketch-book-cover",
};

// BOOK OUTLINE:
// {bookOutlineWithSubchapters}

export const DEFAULT_SUBTASKS_INTRODUCTION = [
  {
    name: "Structure Name",
  },
  {
    name: "Structure Writing",
  },
];

export const DEFAULT_SUBTASKS_FORESHADOW = [
  {
    isFree: true,
    model: "quill-1-16k",
    name: "Structure Writing",
  },
  {
    name: "Editor Line Break",
  },
];

export const DEFAULT_SUBTASKS_SUBCHAPTER = [
  {
    name: "Structure Name",
  },
  {
    name: "Structure Writing",
  },
  {
    name: "Editor Line Break",
  },
  /*
  {
    name: "Editor",
    outputFormat: "json",
    role: "editor",
    prompt: `Act now as a professional editor for {bookCategory} book. Your goal is to enhance the copy of your writer by providing clear improvements.

Reply in a JSON that follows this structure:
{outputJSON}

Here are your tasks:
- Make sure that this text called {fileName} is coherent with the rest of the book.

- Add (or improve) the beginning of the text to flow with the previous text.

- Add (or improve) the end of the text to flow with the next text.

IF THERE ARE NO IMPROVEMENT, RETURN AN EMPTY ARRAY

BOOK NAME:
{bookName}

BOOK OUTLINE:
{bookOutlineWithSubchapters}

AUTHOR NAME:
{authorName}

PREVIOUS FILE:
{previousFileLastWords}

TEXT:
{spellEditorLineBreak}

JSON:
`,
  },
  */
];

export const DEFAULT_SUBTASKS_IMPROVE = [
  {
    model: "quill-1-global",
    name: "Rewrite",
    prompt: `GOAL:
Make the improvements to the following text:

TEXT:
{text}

IMPROVEMENTS:
{improvements}

YOUR TEXT:
`,
  },
];

export const DATA_SOURCES = {
  CORPUS: "corpus", // Default data source from LLM
  RESEARCHES: "researches",
};

export const TEMPLATES = {
  INTRODUCTION: "introduction",
  FORESHADOW: "foreshadow",
  SUBCHAPTER: "subchapter",
};

export const QUILLS = {
  QUILL_1_GLOBAL: "quill-1-global",
  QUILL_2_GLOBAL: "quill-2-global",
};

export const QUILL_SYMBOLS = {
  [QUILLS.QUILL_1_GLOBAL]: "I",
  [QUILLS.QUILL_2_GLOBAL]: "II",
};

/* export const QUILLS_DESCRIPTIONS = [
  {
    id: "quill-1-global",
    name: "Quill I",
    description: "Writes faster.",
  },
  {
    id: "quill-2-global",
    name: "Quill II",
    description: "Writes better.",
  },
]; */

export const STYLES = {
  PRECISION_PUNCH: "precision-punch",
  ELITE_ELOQUENCE: "elite-eloquence",
  FUNNY_FLAIR: "funny-flair",
  NOSTALGIC_NOTE: "nostalgic-note",
  // POETIC_PULSE: "poetic-pulse",
  ACADEMIC_ACUMEN: "academic-acumen",
};

export const SUGGESTIONS_TYPES = {
  PREDICTION: "prediction",
};

export const DATA_SOURCES_OPTIONS = [
  { value: "corpus", text: "Corpus" },
  { value: "researches", text: "Research" },
];

export const TEMPLATES_OPTIONS = [
  { value: "introduction", text: "Introduction" },
  { value: "foreshadow", text: "Foreshadow" },
  { value: "subchapter", text: "Subchapter" },
];

export const QUILLS_OPTIONS = [
  { value: "quill-1-global", text: "Quill I - Global Writer" },
  // { value: "quill-2-global", text: "Quill II - Global Writer" },
];

export const QUILL_1_OPTIONS = [
  { value: "vanilla-verve", text: "Vanilla Verve", description: "Gentle pacing and soothing language." },
  { value: "precision-punch", text: "Precision Punch", description: "Conversational and straight to the point." },
  { value: "elite-eloquence", text: "Elite Eloquence", description: "Refined language with a touch of sophistication." },
  { value: "academic-acumen", text: "Academic Acumen", description: "Knowledgeable and insightful with scholarly precision." },
];

export const QUILL_2_OPTIONS = [
  {
    value: "louis-queen",
    text: "Louis Queen",
    description:
      "His writing style is dynamic and engaging. It could be describe as conversational while keeping an authoritative tone.",
  },
];

export const WRITERS_OPTIONS = [{ value: "jenna-fairwood", text: "Jenna Fairwood" }];

export const POV_OPTIONS = {
  [FORMAT.FICTION]: [
    {
      value: "first-person",
      name: "First-Person",
      description: "Narration is done by a character using 'I' or 'we,' offering a personal and subjective perspective.",
    },
    {
      value: "third-person-omniscient",
      name: "Third-Person Omniscient",
      description:
        "The narrator knows everything about all characters and events, providing insights into the thoughts and feelings of any character.",
    },
    {
      value: "third-person-objective",
      name: "Third-Person Objective",
      description:
        "The narrator reports events without delving into the thoughts or feelings of characters, akin to an observational camera.",
    },
  ],
  [FORMAT.NON_FICTION]: [
    {
      value: "expository",
      name: "Expository",
      description:
        "The writer explains or informs in a straightforward manner, focusing on delivering factual information and analysis.",
    },
    {
      value: "persuasive",
      name: "Persuasive",
      description:
        "The author aims to persuade the reader to adopt a certain viewpoint or take a specific action, often seen in opinion pieces or persuasive essays.",
    },
    {
      value: "descriptive",
      name: "Descriptive",
      description:
        "The author provides detailed descriptions to give the reader a clear, vivid picture of the topic, event, or subject being discussed.",
    },
    {
      value: "narrative-non-fiction",
      name: "Narrative in Non-Fiction",
      description:
        "Similar to narrative in fiction, but the stories and characters are real. Used in biographies, memoirs, and historical accounts.",
    },
    {
      value: "reflective",
      name: "Reflective",
      description:
        "The writer reflects on their own experiences, thoughts, and feelings, often seen in memoirs and personal essays.",
    },
  ],
};

export const PROMPTS = {
  "generate-outline": {
    en: `Generate a detailed chapters list for a non-fiction book in the category $category on $idea, using the following format for each chapter and subchapter:

[
{
"name": "CHAPTER_NAME",
"description": "CHAPTER_DESCRIPTION",
"subchapters": [
{ "name": "SUBCHAPTER_NAME", "description": "SUBCHAPTER_DESCRIPTION" }
]
}
]

The book should be aimed at $audience cover key concepts such as $keyConcepts.
The book should be structured as $structure.

The tone should resemble that of $styleAuthors.
The author of this book is called $authorName.

You should have a minimum of 8 chapters and each chapter should have a minimum of 5 subchapters.

DO NOT ADD THE BOOK NAME, BOOK DESCRIPTION OR BOOK AUTHOR. RETURN ONLY THE CHAPTERS IN THE ARRAY.`,
  },
  "improve-outline": {
    en: `Improve that list of chapters for a non-fiction book in the $category category on $idea.

The intended audience is $audience.
The key concepts and themes to be covered in the book include $keyConcepts.
The writing style and tone should be similar to $styleAuthors.

The author of this book is called $authorName.
Here's more about the author:
$authorData

Here is the current outline of the book:
$bookOutline

Please improve that comprehensive list of chapters for the book and add more chapters.

Use the following format for each chapter and subchapter:
[
{
"name": "CHAPTER_NAME",
"description": "CHAPTER_DESCRIPTION",
"subchapters": [
{ "name": "SUBCHAPTER_NAME", "description": "SUBCHAPTER_DESCRIPTION" }
]
}
]`,
  },
  "generate-file": {
    en: `Act as a world class non fiction book writer.
Your job is to write a part of the chapter $chapterIndex of the book called $bookName.

Here's a summary of the book:
The intended audience is $audience.
The key concepts and themes to be covered in the book include $keyConcepts.
Follow the writing style and tone of $styleAuthors.
Here are is the outline of the book:
$bookOutline

The author of this book is called $authorName.
Here's more about the author:
$authorData

The chapter $chapterIndex is called $chapterName and talks about $chapterDescription.

The specific part you're writing about is specifically about $elementDescription.

DO NOT WRITE THE CHAPTER NUMBER OR THE CHAPTER NAME OR THE PART AT THE BEGINNING`,
  },
  "generate-book-name": {
    en: `Act as a world class non fiction book writer.
Write the name of the book.

Here are is the outline of the book:
$bookOutline

Book Name:`,
  },
  "talk-outline": {
    en: `Act as a world class non fiction book writer.
Your job is to ask me questions until you have all the information you need to get my book outline.
Once you have them, write 'I've got all the informations I need. Now, just click on the blue button 'Continue''`,
    fr: `Agissez en tant que mon assistant écrivain.
Votre travail consiste à me poser des questions jusqu'à ce que vous ayez toutes les informations nécessaires pour générer le plan de mon livre.
Une fois que vous les avez, écrivez 'J'ai toutes les informations nécessaires. Maintenant, cliquez sur le bouton bleu 'Continuer'`,
  },
  [ACTIONS_REWRITE.SUMMARIZATION]: {
    en: `Act as a world class non fiction book writer.
Summarize that text:
$textToRewrite
`,
  },
  [ACTIONS_REWRITE.SIMPLIFICATION]: {
    en: `Act as a world class non fiction book writer.
Simplify that text:
$textToRewrite
`,
  },
  [ACTIONS_REWRITE.CLARIFICATION]: {
    en: `Act as a world class non fiction book writer.
Rewrite that text with more precisions:
$textToRewrite
`,
  },
  [ACTIONS_REWRITE.CONSISTENCY]: {
    en: `Act as a world class non fiction book writer.
Rewrite the text given to be more consistent with the text before and after:

TEXT BEFORE:
$textBefore

TEXT AFTER:
$textAfter

TEXT TO REWRITE:
$textToRewrite
`,
  },
  "predict-text": {
    en: `Act as a world class non fiction book writer.
Predict what could next with a maximum of one paragraph. End it with a .

$text`,
  },
};

export const STYLES_PROMPTS = [
  {
    id: "precision-punch",
    name: "Precision Punch",
    prompt: {
      en:
        // "Rewrite the following text by making it more direct. The sentences must have a maximum of 9 words. However, keep the main idea and logic. Don't change the meaning.",
        "Rewrite the following text in a style that is first-person narrative, conversational, reflective, with vivid imagery and contrasts, suspenseful, relatable, and descriptive.",
      fr:
        "Réécris le texte suivant en le rendant plus direct. Les phrases doivent avoir un maximum de 9 mots. Cependant, gardez l'idée principale et la logique. Ne changez pas le sens.",
    },
    example: {
      en: `Text:
Have you ever wondered what it would feel like to quiet the noise of the world and find a sense of calm within yourself? To tap into a wellspring of peace and tranquility that exists amidst the chaos of everyday life? In a society consumed by constant stimulation and distractions, it is all too easy to overlook the power of silence and introspection. But what if I told you that within the pages of this book lies the key to unlocking the art of meditation, a timeless practice that can revolutionize the way you experience the world and your own inner being? Welcome to "The Art of Meditation," a guide that will lead you on a transformative journey towards self-discovery, profound peace, and spiritual growth.

Variation:
Ever felt the weight of the world's chaos and just craved a moment of peace? You know, that deep-rooted calm that seems elusive in our crazy, buzzing world? Let's face it, with the bombardment of daily distractions, we often miss the magic of silence and reflection. Now, what if I hinted there's a game-changer tucked inside this book? Dive in, and you'll uncover the sacred secrets of meditation - an age-old ritual that could flip the script on how you vibe with the world and groove with your soul. Get ready, pal. "The Art of Meditation" is your golden ticket to an epic adventure of self, serenity, and skyrocketing growth. Dive in!`,
      fr: `Texte :
Vous êtes-vous déjà demandé ce que cela ferait de calmer le bruit du monde et de trouver un sentiment de calme en vous-même ? Pour puiser dans une source de paix et de tranquillité qui existe au milieu du chaos de la vie quotidienne ? Dans une société consumée par des stimulations et des distractions constantes, il est trop facile de négliger le pouvoir du silence et de l'introspection. Mais que se passerait-il si je vous disais que dans les pages de ce livre se trouve la clé pour déverrouiller l'art de la méditation, une pratique intemporelle qui peut révolutionner la façon dont vous vivez le monde et votre propre être intérieur ? Bienvenue dans "L'art de la méditation", un guide qui vous conduira dans un voyage transformateur vers la découverte de soi, la paix profonde et la croissance spirituelle.

Variation:
Avez-vous déjà ressenti le poids du chaos mondial et avez-vous juste rêvé d'un moment de paix ? Vous savez, ce calme profondément enraciné qui semble insaisissable dans notre monde fou et bourdonnant ? Avouons-le, avec le bombardement des distractions quotidiennes, la magie du silence et de la réflexion nous manque souvent. Maintenant, et si j'insinuais qu'il y a un changeur de jeu caché à l'intérieur de ce livre ? Plongez et vous découvrirez les secrets sacrés de la méditation - un rituel séculaire qui pourrait renverser le scénario sur la façon dont vous vibrez avec le monde et groovez avec votre âme. Préparez-vous, mon pote. "L'art de la méditation" est votre billet d'or pour une aventure épique de soi, de sérénité et de croissance fulgurante. Plonger!`,
    },
  },
  {
    id: "elite-eloquence",
    name: "Elite Eloquence",
    prompt: {
      en: "Transform the provided text into a formal tone. Ensure it sounds professional and avoids casual language.",
      fr:
        "Transformez le texte fourni en un ton formel. Assurez-vous que cela semble professionnel et évitez le langage courant.",
    },
    example: {
      en: `Text:
Hey there! Hope you're doing good. Just wanted to let you know that I'll be taking off next Friday. Got some personal stuff to handle.

Variation:
Greetings,
I hope this message finds you well. I would like to formally notify you that I will be unavailable next Friday due to personal commitments.`,
      fr: `Texte:
Salut! J'espère que tu vas bien. Je voulais juste vous faire savoir que je partirai vendredi prochain. J'ai des trucs personnels à gérer.

Variation:
Salutations,
J'espère que ce message vous trouvera bien. Je tiens à vous informer formellement que je serai indisponible vendredi prochain en raison d'engagements personnels.`,
    },
  },
  /*  {
    id: "funny-flair",
    name: "Funny Flair",
    prompt: {
      en: "Add a humorous touch to the text. Make it light-hearted and entertaining without altering the core message.",
      fr: "Ajoutez une touche humoristique au texte. Rendez-le léger et divertissant sans altérer le message principal.",
    },
    example: {
      en: `Text:
I'm on a strict diet and can't eat that cake.

Variation:
I'd love to dive into that cake, but my diet and I are in a very committed relationship right now!`,
      fr: `Texte:
Je suis un régime strict et je ne peux pas manger ce gâteau.

Variation:
J'adorerais plonger dans ce gâteau, mais mon régime et moi sommes dans une relation très engagée en ce moment !`,
    },
  }, */
  /* {
    id: "nostalgic-note",
    name: "Nostalgic Note",
    prompt: {
      en:
        "Infuse the text with a sense of nostalgia. Make it evoke feelings of reminiscence without changing its primary intent.",
      fr:
        "Infusez le texte avec un sentiment de nostalgie. Rendez-le évocateur de souvenirs sans en changer l'intention principale.",
    },
    example: {
      en: `Text:
I used to play in the park with my friends.

Variation:
Those were the golden days, playing in the park with childhood pals, with not a care in the world.`,
      fr: `Texte:
Je jouais dans le parc avec mes amis.

Variation:
C'étaient les jours dorés, à jouer dans le parc avec les camarades de mon enfance, sans aucun souci au monde.`,
    },
  }, */
  {
    id: "academic-acumen",
    name: "Academic Acumen",
    prompt: {
      en:
        "Rephrase the provided text with a scholarly tone. Use formal language, ensure clarity, and incorporate academic terminology where suitable.",
      fr:
        "Reformulez le texte fourni avec un ton érudit. Utilisez un langage formel, assurez la clarté et incorporez la terminologie académique lorsque cela convient.",
    },
    example: {
      en: `Text:
People buy things because they believe those items will make them happy.

Variation:
Individuals engage in consumer behavior based on the underlying presumption that acquired goods or services will contribute to their subjective well-being.`,
      fr: `Texte:
Les gens achètent des choses parce qu'ils croient que ces articles les rendront heureux.

Variation:
Les individus s'engagent dans un comportement de consommation basé sur la présomption sous-jacente que les biens ou services acquis contribueront à leur bien-être subjectif.`,
    },
  },
  /* {
    id: "poetic-pulse",
    name: "Poetic Pulse",
    prompt: {
      en: "Lend a poetic rhythm and flow to the text. Ensure it maintains an artistic touch while preserving its essence.",
      fr:
        "Donnez un rythme et un flux poétiques au texte. Assurez-vous qu'il conserve une touche artistique tout en préservant son essence.",
    },
    example: {
      en: `Text:
The sun sets, casting a golden hue across the horizon.

Variation:
As the sun takes its bow, the horizon gleams in shades of molten gold, a dance of dusk's embrace.,`,
      fr: `Texte:
Le soleil se couche, jetant une teinte dorée sur l'horizon.

Variation:
Alors que le soleil tire sa révérence, l'horizon brille de teintes d'or fondu, une danse de l'étreinte du crépuscule.`,
    },
  }, */
];

export const PROMPTS_V2 = {
  RESEARCH_EXTRACT: "research-extract",
  RESEARCH_SUMMARIZE: "research-summarize",
  RESEARCH_VECTORIZE: "research-vectorize",
  RESEARCH_SUGGESTIONS: "research-suggestions",
  RESEARCH_RETRIEVE: "research-retrieve",
  RESEARCH_OUTLINE: "research-outline",
  RESEARCH_OUTLINE_ONLY: "research-outline-only",
  SUGGEST_OUTLINE: "suggest-outline",
  SUGGEST_OUTLINE_RESEARCH: "suggest-outline-research",
  SUGGEST_OUTLINE_INSTRUCTIONS: "suggest-outline-instructions",
  SUGGEST_PREDICTION: "suggest-predict",
  SUGGEST_TEXT: "suggest-text",
  SUGGEST_CHAPTER: "suggest-chapter",
  SUGGEST_CHAPTER_RESEARCH: "suggest-chapter-research",
  SUGGEST_CHAPTER_INSTRUCTIONS: "suggest-chapter-instructions",
  SUGGEST_FILE: "suggest-file",
  SUGGEST_FILE_RESEARCH: "suggest-file-research",
  SUGGEST_FILE_INSTRUCTIONS: "suggest-file-instructions",
  SUGGEST_IDEA: "suggest-idea",
  SUGGEST_TITLE: "suggest-title",
  RENAME_INTRODUCTION: "rename-introduction",
  WRITE_FIRST_PARAGRAPH: "write-first-paragraph",
  REWRITE: "rewrite",
  REWRITE_SPELL: "rewrite-spell",
  OUTLINE: "outline",
  OUTLINE_V2: "outline-v2",
  OUTLINE_RESEARCHES: "outline-researches",
  OUTLINE_STRUCTURE: "outline-structure",
  SPELL_STRUCTURE_NAME: "structure-name",
  SPELL_STRUCTURE_WRITING: "structure-writing",
  REPLY_SYSTEM_FILE: "reply-system-file",
  CREATE_BOOK_TITLE: "create-book-title",
  PREDICT_BOOK_TITLE: "predict-book-title",
  CREATE_BOOK_DESCRIPTION: "create-book-description",
  PREDICT_BOOK_DESCRIPTION: "predict-book-description",
  CREATE_BOOK_IDEA: "create-book-idea",
  PREDICT_BOOK_IDEA: "predict-book-idea",
  IMAGE_BOOK_COVER: "image-book-cover",
  WRITE_OUTLINE_NAME: "write-outline-name",
  WRITE_OUTLINE: "write-outline",
  WRITE_CHARACTERS: "write-characters",
  WRITE_FILE: "write-file",
  PICK_FILE_STRUCTURE: "pick-file-structure",
  FORMAT_CHAPTERS_LIST: "sanitize-chapters-list",
  FORMAT_SUBCHAPTERS_LIST: "sanitize-subchapters-list",
};

export const BRUSH_IDS = {
  SD: "sd",
  HD: "hd",
};

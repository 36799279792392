export const INPUTS_TYPE = {
  INPUT: "input",
  TEXTAREA: "textarea",
  SELECT: "select",
  URL: "url",
  TAGS: "tags",
  SELECT_SEARCH: "select-search",
  SELECT_SINGLE: "select-single",
  SWIPE: "swipe",
  AI_MODELS: "ai-models",
  AI_MODELS_V3: "ai-models-v3",
  UPLOAD: "upload",
  AUTHOR_FORM: "author-form",
  FILL_IN_THE_BLANKS: "fill-in-the-blanks",
  FILL_IN_THE_BLANKS_GLOBAL: "fill-in-the-blanks-global",
  FILL_IN_THE_BLANKS_TITLE: "fill-in-the-blanks-title",
  FILL_IN_THE_BLANKS_DESCRIPTION: "fill-in-the-blanks-description",
  FILL_IN_THE_BLANKS_AUDIENCE: "fill-in-the-blanks-audience",
  FILL_IN_THE_BLANKS_AUTHOR: "fill-in-the-blanks-author",
  FILL_IN_THE_BLANKS_IDEA: "fill-in-the-blanks-idea",
  FILL_IN_THE_BLANKS_INSPIRATION: "fill-in-the-blanks-inspiration",
  FILL_IN_THE_BLANKS_CHARACTERS: "fill-in-the-blanks-characters",
  FILL_IN_THE_BLANKS_SYNOPSIS: "fill-in-the-blanks-synopsis",
  FILL_IN_THE_BLANKS_POV: "fill-in-the-blanks-pov",
};

export const FORMAT = {
  FICTION: "fiction",
  NON_FICTION: "non-fiction",
};

export const FORMAT_OPTIONS = [
  { text: "Fiction", value: "fiction" },
  { text: "Non Fiction", value: "non-fiction" },
];

export const LANGUAGE_OPTIONS = [
  { value: "en", text: "English" },
  { value: "en-US", text: "English - American English" },
  { value: "en-GB", text: "English - British English" },
  // { value: "en-WL", text: "English - Welsh" },
  { value: "en-AU", text: "English - Australian English" },
  // { value: "en-NZ", text: "English - New-Zealand" },
  // { value: "en-IN", text: "English - Indian" },
  // { value: "en-IE", text: "English - Irish" },
  // { value: "en-AB", text: "English - Arabian" },
  // { value: "en-ZA", text: "English - South Africa" },
];

export const SIZE_OPTIONS = {
  [FORMAT.FICTION]: [
    // { text: "Short Story (1k to 10k words)", value: "xs" },
    { text: "Novelette (10k to 20k words)", value: "sm" },
    // { text: "Novela (20k to 50k words)", value: "md", condition: (planLevel) => planLevel >= 1 },
    // { text: "Short Novel (50k to 80k words)", value: "lg", condition: (planLevel) => planLevel >= 1 },
    // { text: "Long Novel (80k to 115k words)", value: "xl", condition: (planLevel) => planLevel >= 1 },
  ],
  [FORMAT.NON_FICTION]: [
    // { text: "Workbook (5k to 15k words)", value: "xs" },
    { text: "Small book (5k to 15k words)", value: "xs" },
    { text: "Short book (35k to 50k words)", value: "sm" },
    { text: "Average book (70k to 85k words)", value: "md", condition: (planLevel) => planLevel >= 1 },
    { text: "Full-length book (100k to 115k words)", value: "lg", condition: (planLevel) => planLevel >= 1 },
  ],
};

export const CATEGORIES_V2_OPTIONS = {
  [FORMAT.FICTION]: [
    { text: "Fantasy", value: "fantasy" },
    { text: "Science Fiction", value: "science_fiction" },
    { text: "Mystery", value: "mystery" },
    { text: "Thriller", value: "thriller" },
    { text: "Romance", value: "romance" },
    { text: "Historical Fiction", value: "historical_fiction" },
    { text: "Horror", value: "horror" },
    { text: "Young Adult", value: "young_adult" },
    { text: "Children's Literature", value: "childrens_literature" },
    { text: "Literary Fiction", value: "literary_fiction" },
    { text: "Adventure", value: "adventure" },
    { text: "Dystopian", value: "dystopian" },
    { text: "Magical Realism", value: "magical_realism" },
    { text: "Graphic Novels", value: "graphic_novels" },
    { text: "Contemporary", value: "contemporary" },
    { text: "Urban Fantasy", value: "urban_fantasy" },
    { text: "Paranormal", value: "paranormal" },
    { text: "Crime", value: "crime" },
    { text: "Humor", value: "humor" },
    { text: "Speculative Fiction", value: "speculative_fiction" },
  ],
  [FORMAT.NON_FICTION]: [
    { text: "Memoirs and Autobiographies", value: "Memoirs and Autobiographies" },
    { text: "History", value: "History" },
    { text: "Science", value: "Science" },
    { text: "Self-Help", value: "Self-Help" },
    { text: "Travel Guides", value: "Travel Guides" },
    { text: "Business and Finance", value: "Business and Finance" },
    { text: "Reference Books", value: "Reference Books" },
    { text: "True Crime", value: "True Crime" },
    { text: "Cookbooks", value: "Cookbooks" },
    { text: "Sports and Fitness", value: "Sports and Fitness" },
    { text: "Politics and Current Events", value: "Politics and Current Events" },
    { text: "Art and Photography", value: "Art and Photography" },
    { text: "Philosophy", value: "Philosophy" },
    { text: "Education", value: "Education" },
    { text: "Religion and Spirituality", value: "Religion and Spirituality" },
    { text: "Nature and Environment", value: "Nature and Environment" },
    { text: "Technology and Science Fiction", value: "Technology and Science Fiction" },
    { text: "Relationships", value: "Relationships" },
    { text: "Health", value: "Health" },
  ],
};

// TODO:Replace that with an object that includes fiction and non fiction it
export const CATEGORIES_OPTIONS = [
  { text: "Memoirs and Autobiographies", value: "Memoirs and Autobiographies" },
  { text: "History", value: "History" },
  { text: "Science", value: "Science" },
  { text: "Self-Help", value: "Self-Help" },
  { text: "Travel Guides", value: "Travel Guides" },
  { text: "Business and Finance", value: "Business and Finance" },
  { text: "Reference Books", value: "Reference Books" },
  { text: "True Crime", value: "True Crime" },
  { text: "Cookbooks", value: "Cookbooks" },
  { text: "Sports and Fitness", value: "Sports and Fitness" },
  { text: "Politics and Current Events", value: "Politics and Current Events" },
  { text: "Art and Photography", value: "Art and Photography" },
  { text: "Philosophy", value: "Philosophy" },
  { text: "Education", value: "Education" },
  { text: "Religion and Spirituality", value: "Religion and Spirituality" },
  { text: "Nature and Environment", value: "Nature and Environment" },
  { text: "Technology and Science Fiction", value: "Technology and Science Fiction" },
  { text: "Relationships", value: "Relationships" },
  { text: "Health", value: "Health" },
];

export const SETTINGS_BLANK = [
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Language",
    headline: "In Which Language",
    description: "In which language your book will be written?",
    key: "bookLanguage",
    footprints: "More languages are coming.",
    options: [
      { text: "English", value: "en" },
      /* { text: "Français (French)", value: "fr" }, */
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Format",
    headline: "Fiction Or Non Fiction",
    description: "What are you going to write, a fiction or a non-fiction?",
    key: "bookFormat",
    options: [
      { text: "Non Fiction", value: "non-fiction" },
      { text: "Fiction", value: "fiction" },
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.INPUT,
    group: "Book Settings",
    name: "Title",
    headline: "The Title Of Your Book",
    description: "What's the title of your book?",
    key: "bookName",
    placeholder: "Stellar Simplified: A Layman's Guide to Astrophysics",
    isShowingNext: true,
    isRequired: true,
    isShowingOnLp: true,
  },
  /* {
    format: INPUTS_TYPE.SELECT_SINGLE,
    group: "style",
    name: "Quill",
    headline: "Select Your Favorite AI Quill",
    description: "",
    key: "quillId",


    options: [
      {
        name: "None",
        description: "Go old school. Disable all AI options in this project.",
        value: null,
      },
      {
        name: "Quill I",
        description: "Quill I is fast and cost effective. It's perfect to write a draft.",
        value: "quill-1-global",
      },
      {
        name: "Quill II",
        description: "Quill II is slower, and has a heavy consumption. However, it writes better.",
        value: "quill-2-global",
      },
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  }, */
];

export const SETTINGS_SIMPLE_SENTENCE_STEP_1 = [
  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_GLOBAL,
    group: "Book Settings",
    name: "Category",
    headline: "Fill in the blank",
    description: "",
    key: "settingsSentence",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },
];

export const SETTINGS_SIMPLE_SENTENCE_STEP_2 = {
  [FORMAT.FICTION]: [
    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_POV,
      group: "Book Settings",
      name: "Narration",
      headline: "Choosing the Point of View",
      description: "The perspective from which your story is told, shaping how readers connect with characters and events.",
      advice: `<p>Decide on the Point of View for your narrative. Consider how different POVs (First-Person, Third-Person, etc.) might impact the reader's experience. <strong>Think about whose eyes you want the story to be seen through, whose thoughts you wish to explore, or if an omniscient voice suits your narrative best.</strong></p>
      <p><em>This decision sets the tone for your storytelling. QuietQuill is here to guide you in selecting the most compelling POV for your narrative, enhancing the connection between your readers and your story.</em></p>`,
      placeholder: `Decide and describe the Point of View for your narrative. Consider how it impacts the reader's experience.`,
      key: "settingsSentence",
      options: [],
      requiredFields: [],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },

    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_DESCRIPTION,
      group: "Book Settings",
      name: "Main Idea",
      headline: "Description",
      description: "",
      advice: `<p>Explain the main idea of your book. Don't delve into detail or aim for perfection at this stage. <strong>Introduce your lead characters and describe the one thing they are desperate to achieve.</strong> Keep it under 100 words.</p>
<p><em>This is just your starting point. QuietQuill is here to help you refine and polish your idea, turning it into a precious diamond one step at a time.</em></p>`,
      placeholder: `Explain the main idea of your book. Don't delve into details or aim for perfection at this stage. Keep it under 100 words.`,
      key: "settingsSentence",
      options: [],
      requiredFields: [],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },

    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_CHARACTERS,
      group: "Book Settings",
      name: "Characters",
      headline: "The Characters",
      description: "",
      advice: `<p>Write down all the important characters. Add their names, roles, goals and eventually their back story. You can be very specific or broad, QuietQuill will make it look good after. Remember, <strong>clarity is your ally at this stage</strong> — it's less about crafting the perfect characters and <strong>more about understanding the dynamic of each one of them</strong>.</p>
<p><em>Every detail you add brings your vision closer to life.</em></p>`,
      placeholder: "Explore and clarify the core themes and structure of your book.",
      key: "settingsSentence",
      options: [],
      requiredFields: [],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },
    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_SYNOPSIS,
      group: "Book Settings",
      name: "Synopsis",
      headline: "The Synopsis",
      description: "",
      advice: `<p>Write down the flow of your story. Where it starts, what happens and how it ends. You can just jot it down in simple bullet points or be extremely specific and detail each scene you want to write.</p>`,
      placeholder: "Explore and clarify the core themes and structure of your book.",
      key: "settingsSentence",
      options: [],
      requiredFields: [],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },

    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_TITLE,
      group: "Book Settings",
      name: "Title",
      headline: "The Title Of Your Book",
      description: "",
      advice: "Craft a title that piques curiosity by unveiling something unexpected, yet is simple and precise.",
      key: "settingsSentence",
      options: [],
      requiredFields: ["bookName"],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },

    /*  {
      format: INPUTS_TYPE.AI_MODELS,
      group: "Writing Style",
      name: "Style",
      headline: "What's Your Writing Style?",
      description: "Select the the writing style you'd like to use. This can be change in the future.",
      // footprints: "Each answer trains QuietQuill AI's writing style.",
      keys: ["styleId"],
      isShowingNext: false,
      isRequired: false,
      isShowingOnLp: true,
    }, */
  ],
  [FORMAT.NON_FICTION]: [
    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_DESCRIPTION,
      group: "Book Settings",
      name: "Description",
      headline: "A Description Of Your Book",
      description: "",
      advice: `<p>Explain the main idea of your book. Don't delve into details or aim for perfection at this stage. Keep it under 100 words.</p>
<p><em>This is just your starting point. QuietQuill is here to help you refine and polish your idea, turning it into a precious diamond one step at a time.</em></p>`,
      placeholder: `Explain the main idea of your book. Don't delve into details or aim for perfection at this stage. Keep it under 100 words.`,
      key: "settingsSentence",
      examples: "main-idea",
      options: [],
      requiredFields: [],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },

    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_IDEA,
      group: "Book Settings",
      name: "Concept",
      headline: "Ideas, Concepts, And Suggested Outline",
      description: "",
      advice: `<p>Dive deeper into the essence of your book.</p><p>Elaborate on the pivotal concepts, ideas, or lessons that drive your narrative. If you have a basic outline in mind, jot it down in simple bullet points. QuietQuill will make it look good after. Remember, <strong>clarity is your ally at this stage</strong> — it's less about crafting the perfect chapter titles and <strong>more about understanding the flow of your content</strong>.</p>
<p><em>Every detail you add brings your vision closer to life.</em></p>`,
      placeholder: "Explore and clarify the core themes and structure of your book.",
      key: "settingsSentence",
      options: [],
      requiredFields: [],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },

    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_INSPIRATION,
      group: "Book Settings",
      name: "References",
      headline: "Fill in the blank",
      description: "",
      footprints: "List up to 3 authors or books as references and that inspire you.",
      advice: `<p>QuietQuill doesn't use copyrighted data or books. However, it looks for public informations to understand more about your chosen inspirations, ensuring to write a book coherent with your vision.</p>
<p><em>Every author stands on the shoulders of giants.</em></p>`,
      key: "settingsSentence",
      options: [],
      requiredFields: [],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },

    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_TITLE,
      group: "Book Settings",
      name: "Title",
      headline: "The Title Of Your Book",
      description: "",
      advice:
        "Craft a title that piques curiosity by unveiling something unexpected, yet is simple and precise. Ensure it resonates with your target audience's interests or challenges, and consider the use of a clarifying subtitle to provide additional context.",
      key: "settingsSentence",
      options: [],
      requiredFields: ["bookName"],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },

    /* {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_AUDIENCE,
    group: "Book Settings",
    name: "SingleSentence",
    headline: "Fill in the blank",
    description: "",
    key: "settingsSentence",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  }, */

    {
      format: INPUTS_TYPE.FILL_IN_THE_BLANKS_AUTHOR,
      group: "Book Settings",
      name: "Author",
      headline: "Fill in the blank",
      description: "",
      key: "settingsSentence",
      options: [],
      requiredFields: [],
      isShowingNext: true,
      isShowingOnLp: true,
      isRequired: false,
    },
    /* {
    format: INPUTS_TYPE.SELECT_SINGLE,
    group: "style",
    name: "Quill",
    headline: "Select Your Favorite AI Quill",
    description: "",
    key: "quillId",
    options: [
      {
        name: "None",
        description: "Go old school. Disable all AI options in this project.",
        value: null,
      },
      {
        name: "Quill I",
        description: "Quill I is fast and cost effective. It's perfect to write a draft.",
        value: "quill-1-global",
      },
      {
        name: "Quill II",
        description: "Quill II is slower, and has a heavy consumption. However, it writes better.",
        value: "quill-2-global",
      },
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  }, */

    /*  {
      format: INPUTS_TYPE.AI_MODELS,
      group: "Writing Style",
      name: "Style",
      headline: "What's Your Writing Style?",
      description: "Select the the writing style you'd like to use. This can be change in the future.",
      // footprints: "Each answer trains QuietQuill AI's writing style.",
      keys: ["styleId"],
      isShowingNext: false,
      isRequired: false,
      isShowingOnLp: true,
    }, */
  ],
};

export const SETTINGS_PROBLEM_TO_MANUSCRIPT = [
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Language",
    headline: "In Which Language",
    description: "In which language your book will be written?",
    key: "bookLanguage",
    footprints: "More languages are coming.",
    options: [
      { text: "English", value: "en" },
      /* { text: "Français (French)", value: "fr" }, */
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Ideal Length",
    headline: "Choose The Ideal Length For Your Book",
    description: "Give us an estimation of the amount of words you're looking to write.",
    key: "bookSize",
    footprints: "Generating a book of more than 70k words is only available to Writers and above.",
    options: [
      { text: "Workbook (5k to 15k words)", value: "xs" },
      { text: "Short book (35k to 50k words)", value: "sm" },
      { text: "Average book (70k to 85k words)", value: "md", condition: (planLevel) => planLevel >= 1 },
      { text: "Full-length book (100k to 115k words)", value: "lg", condition: (planLevel) => planLevel >= 1 },
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_1,
    group: "Book Settings",
    name: "SingleSentence",
    headline: "Fill in the blank",
    description: "",
    key: "settingsSentence",
    options: [],
    requiredFields: ["bookName"],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },
];

export const SETTINGS_WEBINAR_TO_MANUSCRIPT = [
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Language",
    headline: "In Which Language",
    description: "In which language your book will be written?",
    key: "bookLanguage",
    footprints: "More languages are coming.",
    options: [
      { text: "English", value: "en" },
      /* { text: "Français (French)", value: "fr" }, */
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.INPUT,
    group: "Book Settings",
    name: "Title",
    headline: "The Title Of Your Book",
    description: "What's the title of your book?",
    key: "bookName",
    placeholder: "Stellar Simplified: A Layman's Guide to Astrophysics",
    isShowingNext: true,
    isRequired: true,
    isShowingOnLp: true,
  },
];

export const SETTINGS_SIMPLE = [
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Language",
    headline: "In Which Language",
    description: "In which language your book will be written?",
    key: "bookLanguage",
    footprints: "More languages are coming.",
    options: [
      { text: "English", value: "en" },
      /* { text: "Français (French)", value: "fr" }, */
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Format",
    headline: "Fiction Or Non Fiction",
    description: "What are you going to write, a fiction or a non-fiction?",
    key: "bookFormat",
    footprints: "Fiction is coming this October.",
    options: [
      { text: "Non Fiction", value: "non-fiction" },
      { text: "Fiction", value: "fiction" },
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Ideal Length",
    headline: "Choose The Ideal Length For Your Book",
    description: "Give us an estimation of the amount of words you're looking to write.",
    key: "bookSize",
    footprints: "Generating a book of more than 70k words is only available to Writers and above.",
    options: [
      { text: "Workbook (5k to 15k words)", value: "xs" },
      { text: "Short book (35k to 50k words)", value: "sm" },
      { text: "Average book (70k to 85k words)", value: "md", condition: (planLevel) => planLevel >= 1 },
      { text: "Full-length book (100k to 115k words)", value: "lg", condition: (planLevel) => planLevel >= 1 },
      /* { text: "Full-length book (100k to 115k words)", value: "lg", condition: (planLevel) => planLevel >= 2 }, */
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.SELECT_SEARCH,
    group: "Book Settings",
    name: "Category",
    headline: "Select The Category That Fits Your Book The Most",
    description: "Health, self-help, or even cooking-book? What are you looking to create?",
    key: "bookCategory",
    options: [...CATEGORIES_OPTIONS],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  /* {
    format: INPUTS_TYPE.SELECT_SINGLE,
    group: "Book Focus",
    name: "Focus",
    headline: "Author-Centric Or Content-Centric?",
    description: "",
    key: "bookFocus",
    options: [
      {
        name: "Author-Centric",
        description:
          "These books heavily rely on the author's personal experiences, anecdotes, and unique voice. They may be written in a conversational tone and often feel like a one-on-one chat with the author. The author's personality is a significant part of the book's appeal.",
        value: "author-centric",
      },
      {
        name: "Content-Centric",
        description:
          "These books might contain personal anecdotes, but the primary focus is on the content, strategies, or concepts being presented. They may be based on research, studies, or established practices and generally maintain a more formal or educational tone.",
        value: "content-centric",
      },
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  }, */
  {
    format: INPUTS_TYPE.INPUT,
    group: "Book Settings",
    name: "Title",
    headline: "The Title Of Your Book",
    description: "What's the title of your book?",
    key: "bookName",
    placeholder: "Stellar Simplified: A Layman's Guide to Astrophysics",
    isShowingNext: true,
    isRequired: true,
    isShowingOnLp: true,
  },
  {
    format: INPUTS_TYPE.TEXTAREA,
    group: "Book Settings",
    name: "Description",
    headline: "A Description Of Your Book",
    description:
      "Write a description of your book. Whether it's a single sentence or a detailed description of what must be in your draft.",
    key: "bookDescription",
    placeholder:
      "A book that simplifies the fundamentals of astrophysics, covering topics such as the Big Bang, the mechanics of gravity, various types of galaxies, and more.",
    minTokens: 8,
    mediumTokens: 250,
    maxTokens: 500,
    isShowingNext: true,
    isRequired: false,
    isWritingCopy: true,
    isShowingOnLp: true,
  },
  {
    format: INPUTS_TYPE.TEXTAREA,
    group: "Audience Settings",
    name: "Audience Description",
    headline: "A Description Of Your Audience",
    description: "Who is the target audience for your book?",
    placeholder: "Students and science lovers",
    key: "audienceDescription",
    minTokens: 3,
    mediumTokens: 10,
    maxTokens: 100,
    isShowingNext: true,
    isRequired: false,
    isShowingOnLp: true,
  },
  {
    format: INPUTS_TYPE.INPUT,
    group: "Author's Settings",
    name: "Author's Name",
    headline: "Your Author's Name",
    description: "What's your pen name?",
    key: "authorName",
    placeholder: "Louis A. Queen",
    isShowingNext: true,
    isRequired: false,
    isShowingOnLp: true,
  },
  {
    format: INPUTS_TYPE.TEXTAREA,
    group: "Author's Settings",
    name: "Author's Biography",
    headline: "Tell Us More About You",
    description: "Who are you? Why are you writing this book? What's your story?",
    placeholder: "An experienced astrophysicist and a great lover of science.",
    key: "authorBiography",
    minTokens: 3,
    mediumTokens: 100,
    maxTokens: 500,
    isShowingNext: true,
    isRequired: false,
    isShowingOnLp: true,
  },
  {
    format: INPUTS_TYPE.SELECT,
    group: "Book Settings",
    name: "Language",
    headline: "In Which Language",
    description: "In which language your book will be written?",
    key: "bookLanguage",
    footprints: "More languages are coming.",
    options: [
      { text: "English", value: "en" },
      /* { text: "Français (French)", value: "fr" }, */
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_GLOBAL,
    group: "Book Settings",
    name: "SingleSentence",
    headline: "Fill in the blank",
    description: "",
    key: "settingsSentence",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },
  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_DESCRIPTION,
    group: "Book Settings",
    name: "SingleSentence",
    headline: "An Overview Of Your Book",
    description: "",
    advice: `<p>Explain the main idea of your book. Don't delve into details or aim for perfection at this stage. Keep it under 100 words.</p>
<p><em>This is just your starting point. QuietQuill is here to help you refine and polish your idea, turning it into a precious diamond one step at a time.</em></p>`,
    key: "settingsSentence",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },
];

export const SETTINGS_SIMPLE_V2 = [
  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_GLOBAL,
    group: "Book Settings",
    name: "Category",
    headline: "Fill in the blank",
    description: "",
    key: "settingsSentence",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },
  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_DESCRIPTION,
    group: "Book Settings",
    name: "Description",
    headline: "The Description Of Your Book",
    description: "",
    advice: `<p>Explain the main idea of your book. Don't delve into details or aim for perfection at this stage. Keep it under 100 words.</p>
<p><em>This is just your starting point. QuietQuill is here to help you refine and polish your idea, turning it into a precious diamond one step at a time.</em></p>`,
    placeholder: `Explain the main idea of your book. Don't delve into details or aim for perfection at this stage. Keep it under 100 words.`,
    key: "settingsSentence",
    examples: "main-idea",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },
  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_TITLE,
    group: "Book Settings",
    name: "Title",
    headline: "The Title Of Your Book",
    description: "",
    advice:
      "Craft a title that piques curiosity by unveiling something unexpected, yet is simple and precise. Ensure it resonates with your target audience's interests or challenges, and consider the use of a clarifying subtitle to provide additional context.",
    examples: "title",
    placeholder: "My Book",
    key: "settingsSentence",
    options: [],
    requiredFields: ["bookName"],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },

  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_AUTHOR,
    group: "Book Settings",
    name: "Author",
    headline: "Fill in the blank",
    description: "",
    key: "settingsSentence",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },

  /* {
    format: INPUTS_TYPE.AI_MODELS,
    group: "Writing Style",
    name: "Writing Style",
    headline: "What's Your Writing Style?",
    description: "Select the the writing style you'd like to use. This can be change in the future.",
    footprints: "This can be change in the future.",
    keys: ["styleId"],
    isShowingNext: false,
    isRequired: false,
    isShowingOnLp: true,
  }, */
];

export const SETTINGS_COVER = [
  {
    format: INPUTS_TYPE.INPUT,
    group: "Book Settings",
    name: "Title",
    headline: "The Title Of Your Book",
    description: "Here's the title of my book:",
    advice: "This title will appear on your book cover.",
    examples: null,
    placeholder: "The title...",
    key: "bookName",
    options: [],
    requiredFields: ["bookName"],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },

  {
    format: INPUTS_TYPE.FILL_IN_THE_BLANKS_DESCRIPTION,
    group: "Book Settings",
    name: "Description",
    headline: "The Description Of Your Book",
    description: "",
    advice: `<p>Explain what your book is about. QuietQuill will use that information to suggest the best covers.</p>`,
    placeholder: `The description...`,
    key: "bookDescription",
    examples: "",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },

  {
    format: INPUTS_TYPE.INPUT,
    group: "Book Settings",
    name: "Author",
    headline: "The Author's Name",
    description: "Here's the author's name",
    placeholder: `The name...`,
    advice: "The author's name will appear on your book cover.",
    key: "authorName",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: false,
  },

  {
    format: INPUTS_TYPE.TEXTAREA,
    group: "Book Settings",
    name: "Idea",
    headline: "The Idea For Your Cover",
    description: "Here's a description of my cover...",
    advice: `<p>Explain what you'd like to see on your book cover. QuietQuill will use that information to create the cover of your dreams.</p>`,
    placeholder: `The description...`,
    key: "coverDescription",
    examples: "cover-description",
    options: [],
    requiredFields: ["coverDescription"],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
];

export const SETTINGS_EXPERT_STEP_1 = [
  {
    // format: INPUTS_TYPE.URL,
    format: INPUTS_TYPE.URL,
    group: "Book Settings",
    name: "You",
    headline: "Tell Us More About You",
    description: "Here's my website that shows my work and expertise:",
    advice: "Your website will help us understand who you are and your expertise.",
    footprints: "You don't have a website? Click here.",
    footprintsAction: "show-form",
    examples: null,
    placeholder: "https://my-awesome-website.com",
    key: "authorUrl",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
];

export const SETTINGS_EXPERT_STEP_2 = [
  {
    format: INPUTS_TYPE.SELECT_SINGLE,
    group: "Book Settings",
    name: "Title & Description",
    headline: "The Title And Description Of Your Book",
    description: "Here's the description of my book:",
    advice: `<p>Explain the main idea of your book. Don't delve into detail or aim for perfection at this stage. <strong>Introduce your lead characters and describe the one thing they are desperate to achieve.</strong> Keep it under 100 words.</p>
    <p><em>This is just your starting point. QuietQuill is here to help you refine and polish your idea, turning it into a precious diamond one step at a time.</em></p>`,
    examples: null,
    placeholder: "",
    key: "itemValue",
    options: [],
    requiredFields: [],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
];

export const SETTINGS_CONFIRMATION = {
  [FORMAT.NON_FICTION]: [
    {
      format: INPUTS_TYPE.AI_MODELS_V3,
      group: "Writing Style",
      name: "Intro",
      headline: "The First Words Of Your Book",
      description:
        "We're writing three different possible openings for your book with different AI writers. Choose the one that resonates with you the most or press 'Skip' if you prefer to write it yourself.",
      // footprints: "Each answer trains QuietQuill AI's writing style.",
      advice: `<p>The first words of your book are the most important. Not only they set the tone of the rest of the book, but they also set the first impression... And you've got only one shot at that!</p>
  <p>Choose your favorite AI writer, each one of them has its own style and perception of the world. <strong>Those words are unique and have been written only for you.</strong></p>`,
      keys: ["styleId"],
      isShowingNext: false,
      isRequired: false,
      isShowingOnLp: true,
    },
  ],
  [FORMAT.FICTION]: [],
};

export const SETTINGS_CONFIRMATION_WEBINAR_TO_MANUSCRIPT = [
  {
    format: INPUTS_TYPE.UPLOAD,
    group: "Book Settings",
    name: "Webinar",
    headline: "Upload The Webinar",
    description: "Upload your webinar in mp4.",
    key: "researchId",
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  },
  /* {
    format: INPUTS_TYPE.SELECT_SINGLE,
    group: "style",
    name: "Quill",
    headline: "Select Your Favorite Quill",
    description: "",
    key: "quillId",
    options: [
      {
        name: "Quill I",
        description: "Quill I is fast and cost effective. It's perfect to write a draft.",
        value: "quill-1-global",
      },
      {
        name: "Quill II",
        description: "Quill II is slower, and has a heavy consumption. However, it writes better.",
        value: "quill-2-global",
      },
    ],
    isShowingNext: true,
    isShowingOnLp: true,
    isRequired: true,
  }, */
  /*  {
    format: INPUTS_TYPE.AI_MODELS,
    group: "Writing Style",
    name: "Style",
    headline: "Swipe Right On What You Like",
    description:
      "We've Magic Written multiple paragraphs of your future book in different styles. Tell us the ones you love and the one you don't.",
    footprints: "Each answer trains QuietQuill AI's writing style.",
    keys: ["styleId"],
    isShowingNext: false,
    isRequired: false,
    isShowingOnLp: true,
  }, */
];

/* When adding a new child here, also add it inside the firebase realtime database security rules validation */
export const DEFAULT_SETTINGS = {
  bookLanguage: "en",
  bookFormat: "non-fiction",
  bookCategory: "Memoirs and Autobiographies",
  bookSize: "sm",
  bookName: "",
  bookDescription: "",
  bookIdeas: "",
  bookInspirations: "",
  bookCharacters: "",
  bookSynopsis: "",
  bookPov: "",
  audienceDescription: "",
  authorName: "",
  authorBiography: "",
  authorUrl: "",
  quillId: "quill-1-global",
  styleId: "vanilla-verve",
};

export const DEFAULT_CATEGORY = {
  [FORMAT.FICTION]: "fantasy",
  [FORMAT.NON_FICTION]: "Memoirs and Autobiographies",
};

export const DEFAULT_SUGGESTIONS = {
  ["main-idea"]: {
    [FORMAT.NON_FICTION]: {
      ["Memoirs and Autobiographies"]: {
        name: "Echoes of a Life Lived Loudly",
        description:
          "This memoir explores the life of a renowned musician, delving into their journey from humble beginnings to international fame. The book offers an intimate look at the challenges and triumphs faced in both their personal life and career, revealing untold stories and the true essence of living under the spotlight.",
      },
      ["History"]: {
        name: "Empires in the Dust: A Journey Through Time",
        description:
          "An insightful exploration of the rise and fall of great civilizations throughout history. This book provides a detailed analysis of the socio-political and economic factors that led to the emergence and eventual decline of some of the most influential empires in human history.",
      },
      ["Science"]: {
        name: "Unveiling the Cosmos: Secrets of the Universe",
        description:
          "A captivating journey through the mysteries of the universe, blending complex scientific concepts with accessible language. This book covers groundbreaking discoveries in physics and astronomy, offering a glimpse into the wonders of space and the ongoing quest for understanding the cosmos.",
      },
      ["Self-Help"]: {
        name: "Temporal Journeys: Harnessing Your Past and Future for a Better Now",
        description:
          "A self-help guide centered around the concept of 'time-traveling' within one's own life. It explores how revisiting past experiences and projecting into future scenarios in a structured, reflective manner can aid in personal growth and decision-making.",
      },
      ["Travel Guides"]: {
        name: "Wanderlust Wonders: Exploring the World's Hidden Gems",
        description:
          "A travel guide featuring off-the-beaten-path destinations around the globe. With stunning photography and insightful commentary, this book inspires readers to explore diverse cultures, landscapes, and experiences beyond the usual tourist trails.",
      },
      ["Business and Finance"]: {
        name: "The Innovator's Mindset: Transforming Your Business",
        description:
          "A compelling read for entrepreneurs and business leaders, focusing on innovative strategies for transforming businesses in the modern world. The book combines case studies, personal experiences, and practical advice to inspire and guide readers in the dynamic field of business and finance.",
      },
      ["Reference Books"]: {
        name: "The Encyclopedia of Everything: A World of Knowledge",
        description:
          "An extensive reference book covering a wide array of topics from history and science to art and literature. Designed to be both informative and accessible, it serves as a valuable resource for students, educators, and curious minds alike.",
      },
      ["True Crime"]: {
        name: "Shadows of Guilt: Unraveling Unsolved Mysteries",
        description:
          "A gripping collection of true crime stories, exploring some of the most puzzling and notorious unsolved cases. The book delves into the investigations, the suspects, and the mysteries that still surround these chilling events.",
      },
      ["Cookbooks"]: {
        name: "Flavors of the World: A Culinary Adventure",
        description:
          "A cookbook that takes readers on a gastronomic journey across continents. It features a diverse range of recipes, showcasing traditional dishes from various cultures, complete with tips on ingredients and cooking techniques.",
      },
      ["Sports and Fitness"]: {
        name: "The Athlete's Way: Training for Peak Performance",
        description:
          "A comprehensive guide to sports and fitness, covering various aspects of physical training, nutrition, and mental preparation. Aimed at athletes of all levels, this book provides insights into achieving and maintaining peak performance.",
      },
      ["Politics and Current Events"]: {
        name: "Voices of Change: Shaping the Future",
        description:
          "An analysis of current political trends and events, offering perspectives on how they are shaping the future of societies globally. The book discusses key issues in modern politics, including democracy, human rights, and international relations.",
      },
      ["Art and Photography"]: {
        name: "Canvas of Light: The Art of Seeing",
        description:
          "A visually stunning book on art and photography, exploring the interplay of light, composition, and subject matter. It includes insights from renowned artists and photographers, along with a collection of striking images.",
      },
      ["Philosophy"]: {
        name: "Thoughts on Existence: Perspectives on Life and Reality",
        description:
          "A philosophical exploration into the nature of existence, reality, and consciousness. The book invites readers to ponder deep questions about life, blending ancient wisdom with contemporary thought.",
      },
      ["Education"]: {
        name: "The Learning Revolution: Rethinking Education for the 21st Century",
        description:
          "An insightful look at the future of education, exploring innovative teaching methods, the impact of technology in learning, and strategies to equip students for a rapidly changing world.",
      },
      ["Religion and Spirituality"]: {
        name: "Echoes of the Divine: Exploring the World's Religions",
        description:
          "A comprehensive guide to the major world religions and spiritual practices. The book delves into the beliefs, rituals, and historical significance of each, fostering understanding and respect among diverse faiths.",
      },
      ["Nature and Environment"]: {
        name: "Whispers of the Wild: Understanding Our Natural World",
        description:
          "A compelling journey into the heart of nature, examining the delicate balance of ecosystems and the impact of human activity on the environment. The book combines breathtaking imagery with insightful commentary on conservation.",
      },
      ["Technology and Science Fiction"]: {
        name: "Futurescapes: Visions of Tomorrow",
        description:
          "A science fiction anthology that imagines the future of technology and its influence on society. The stories explore themes of artificial intelligence, space exploration, and the ethical dilemmas of scientific advancement.",
      },
      ["Relationships"]: {
        name: "Connections: The Art of Building Meaningful Relationships",
        description:
          "An exploration of human relationships, from friendships to romantic partnerships. The book offers practical advice and psychological insights for fostering deeper connections and understanding the dynamics of interpersonal relationships.",
      },
      ["Health"]: {
        name: "The Wellness Path: A Guide to Holistic Health",
        description:
          "A comprehensive resource on holistic health, covering topics like nutrition, exercise, mental well-being, and alternative medicine. It aims to empower readers with knowledge and tips for maintaining a healthy, balanced lifestyle.",
      },
    },
  },
};
